import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import * as Styles from './imageGalleryGrid.module.scss'

const ImageGalleryGridSlice = (props: any) => {
  const { content } = props

  const { items } = content

  return (
    <section className={Styles.imageGalleryContainer}>
      <div className={Styles.imageGalleryInnerContainer}>
        <div className={Styles.eyebrow}>
          <Eyebrow />
        </div>
        <h2>{content.heading}</h2>
        <div className={Styles.imageGalleryGrid}>
          {items.map((item: any) => {
            const { image, alt } = item

            return (
              <div className={Styles.gridImageContainer}>
                <GatsbyImage
                  className={Styles.gridImage}
                  image={image.gatsbyImageData}
                  alt={alt}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ImageGalleryGridSlice
