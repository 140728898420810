import * as React from 'react'
import { graphql } from 'gatsby'

import CateringOptionsAccordion from 'src/components/organisms/ctas/cateringOptionsAccordion'
import CateringOptionsColumn from 'src/components/organisms/ctas/cateringOptionsColumn'

interface Props {
  slice: any
}

const CateringOptionsSlice = ({ slice }: Props) => {
  const content = {
    id: slice.id ?? '',
    items: slice?.items,
    anchor: slice.primary.anchor ?? '',
    description: slice.primary.description?.text ?? '',
    eyebrowText: slice.primary.eyebrow_text?.text ?? '',
    headline: slice.primary.catering_headline?.text ?? '',
    primaryCtaLink: slice.primary.primary_cta_link ?? '',
    primaryCtaText: slice.primary.primary_catering_cta_text ?? '',
    primaryCtaTarget: slice.primary.primary_cta_link?.target ?? '',
  }

  const sliceType = slice?.slice_label

  if (sliceType === 'accordion') {
    return <CateringOptionsAccordion content={content} />
  }
  if (sliceType === 'column') {
    // eslint-disable-next-line
    return <CateringOptionsColumn content={content} />
  }
  return null
}

export default CateringOptionsSlice

export const cateringOptionsFragment = graphql`
  fragment PageDataBodyCateringOptions on PrismicPageDataBodyCateringOptions {
    id
    slice_label
    items {
      cta_link {
        url
      }
      cta_text
      description {
        text
      }
      catering_headline: headline {
        text
      }
      image {
        gatsbyImageData
        alt
      }
    }
    primary {
      anchor
      description {
        text
      }
      catering_eyebrow_text: eyebrow_text {
        text
      }
      catering_headline: headline {
        text
      }
      primary_cta_link {
        url
        target
      }
      primary_catering_cta_text: primary_cta_text
    }
  }
`
