import React from 'react'
import { Link } from 'gatsby'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as Styles from './cateringOptionsAccordion.module.scss'

type Props = {
  content: {
    id: string
    items: any[]
    anchor: string
    description: string
    eyebrowText: string
    headline: string
    primaryCtaLink: {
      url: string
    }
    primaryCtaTarget: string
    primaryCtaText: string
  }
}

const CateringOptionsAccordion = (props: Props) => {
  const [activeAccordion, setActiveAccordion] = React.useState<number | null>(0)
  const { content } = props

  const {
    items,
    description,
    eyebrowText,
    headline,
    primaryCtaLink,
    primaryCtaTarget,
    primaryCtaText,
  } = content

  return (
    <section className={Styles.cateringOptionsAccordion}>
      <div className={Styles.topContainer}>
        <div className={Styles.headingContainer}>
          <p className={Styles.eyebrow}>{eyebrowText}</p>
          <h2>{headline}</h2>
          <Link
            to={primaryCtaLink.url}
            target={primaryCtaTarget}
            className={`${GlobalStyles.redBasicLink} ${Styles.primaryCta}`}
          >
            {primaryCtaText}
          </Link>
        </div>
        <div className={Styles.primaryDescription}>
          <p>{description}</p>
        </div>
      </div>
      <div className={Styles.accordionContainer}>
        <div className={Styles.accordionItems}>
          {items.map((item: any, index: number) => {
            const {
              cta_link: ctaLink,
              cta_text: ctaText,
              description: itemDescription,
              catering_headline: cateringHeadline,
            } = item

            return (
              <div
                key={item.id}
                className={`${Styles.accordionItem} ${
                  activeAccordion === index && Styles.open
                }`}
                onClick={() => setActiveAccordion(index)}
                role="button"
                tabIndex={0}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setActiveAccordion(index)
                  }
                }}
              >
                <div className={Styles.textContainer}>
                  <div className={Styles.accordingHeadingContainer}>
                    <p className={Styles.accordionItemHeading}>
                      {cateringHeadline.text}
                    </p>
                    <span className={Styles.openIcon} />
                  </div>
                  <div className={Styles.accordionCtaContainer}>
                    <p className={Styles.accordionItemDescription}>
                      {itemDescription.text}
                    </p>
                    <Link
                      to={ctaLink.url}
                      className={`${GlobalStyles.redBasicLink} ${Styles.accordionCta}`}
                    >
                      {ctaText}
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={Styles.accordionImageContainer}>
          {items.map((item: any, index: number) => (
            <GatsbyImage
              className={`${Styles.accordionImage} ${
                activeAccordion === index && Styles.accordionImageActive
              }`}
              alt={item.image?.alt || ''}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              image={item.image?.gatsbyImageData}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default CateringOptionsAccordion
