import React, { useEffect, useState } from 'react'
import {
  PrismicFaqCategory,
  PrismicLinkType,
} from 'src/typings/generated/graphql'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import FaqCategoryQuestion, {
  generateFaqCategoryQuestionId,
} from 'src/components/molecules/faqCategoryQuestion'
import { generateRandomId } from 'src/utils/domHelper'
import SearchIcon from 'src/images/svgs/search.svg'

import * as GlobalStyles from 'src/styles/global.module.scss'
import * as Styles from './faqCategory.module.scss'

export type FaqCategoryContent = {
  anchor?: string
  headline?: string
  faqCategory?: PrismicFaqCategory
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: FaqCategoryContent
}

const FaqCategory = ({ content }: Props) => {
  const [searchCriteria, setSearchCriteria] = useState('')
  const [searchResults, setSearchResults]: any = useState([])

  const {
    anchor = generateRandomId(),
    headline = '',
    faqCategory,
    link,
    linkText = '',
  } = content

  interface QuestionAndAnswer {
    question: {
      text: string
    }
  }

  function searchFilter(
    dataArray: any[],
    searchTerm: string
  ): QuestionAndAnswer[] {
    const lowerCaseTerm = searchTerm.toLowerCase()

    return dataArray.filter(item =>
      item.question.text.toLowerCase().includes(lowerCaseTerm)
    )
  }

  function handleSearchValue(event: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = event.target.value
    const searchArray = faqCategory?.data?.question_and_answer || []

    setSearchCriteria(searchValue)
    const filteredResults = searchFilter(searchArray, searchValue)

    setSearchResults(filteredResults)
  }

  useEffect(() => {
    setSearchResults(faqCategory?.data?.question_and_answer)
  }, [])

  return (
    <section id={anchor} className={Styles.faqCategory}>
      <div className={Styles.contentContainer}>
        <h2>{BertholdTitle({ text: headline })}</h2>
        <div className={Styles.searchContainer}>
          <input
            className={Styles.searchInput}
            type="text"
            value={searchCriteria}
            onChange={e => handleSearchValue(e)}
            placeholder="Search your question"
          />
          <SearchIcon className={Styles.searchIcon} />
        </div>
        <div className={Styles.questions}>
          {faqCategory &&
            searchResults?.map((qa: any, index: number) => {
              const questionId = generateFaqCategoryQuestionId(
                faqCategory.id,
                index
              )
              return (
                qa && (
                  <FaqCategoryQuestion
                    id={questionId}
                    question={qa}
                    key={questionId}
                  />
                )
              )
            })}
        </div>
        <CustomLink link={link} className={GlobalStyles.redBasicLink}>
          {linkText}
        </CustomLink>
      </div>
    </section>
  )
}

export default FaqCategory
