import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Quotations from 'src/images/svgs/icons/QuoteIcon-v2.svg'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { Link } from 'gatsby'
import * as Styles from './multiMediaGrid.module.scss'

export type GridContent = {
  quoteText: string
  quoteSource: string
  textBlock: string
  image1: IGatsbyImageData
  image2: IGatsbyImageData
  image3: IGatsbyImageData
  image1Alt: string
  image2Alt: string
  image3Alt: string
  ctaParagraph: string
  ctaLink: string
  ctaTarget: string
  ctaText: string
}

type Props = {
  content: GridContent
}

const MultiMediaGrid = ({ content }: Props) => {
  const {
    quoteText,
    quoteSource,
    textBlock,
    image1,
    image2,
    image3,
    image1Alt,
    image2Alt,
    image3Alt,
    ctaParagraph,
    ctaLink,
    ctaTarget,
    ctaText,
  } = content

  return (
    <section className={Styles.multiMediaGrid}>
      <div className={Styles.gridContainer}>
        <div className={`${Styles.gridItem} ${Styles.gridItemQuote}`}>
          <Quotations />
          <p className={Styles.quoteText}>{quoteText}</p>
          <p className={Styles.quoteSource}>{quoteSource}</p>
        </div>
        <div className={Styles.gridItem}>
          <p className={Styles.textBlockParagraph}>{textBlock}</p>
        </div>
        <div className={Styles.gridItem}>
          <GatsbyImage image={image1} alt={image1Alt} />
        </div>
        <div className={Styles.gridItem}>
          <GatsbyImage image={image2} alt={image2Alt} />
        </div>
        <div className={Styles.gridItem}>
          <GatsbyImage image={image3} alt={image3Alt} />
        </div>
        <div className={Styles.gridItem}>
          <p className={Styles.ctaParagraph}>{ctaParagraph}</p>
          <Link
            to={ctaLink}
            target={ctaTarget}
            className={`${GlobalStyles.redGhostButtonResponsive} ${Styles.ctaLink}`}
          >
            {ctaText}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default MultiMediaGrid
