// extracted by mini-css-extract-plugin
export var cateringOptionsColumn = "cateringOptionsColumn-module--cateringOptionsColumn--f1bc3";
export var columnContainer = "cateringOptionsColumn-module--columnContainer--c68e8";
export var columnCta = "cateringOptionsColumn-module--columnCta--33145";
export var columnHeadingContainer = "cateringOptionsColumn-module--columnHeadingContainer--d3599";
export var columnItem = "cateringOptionsColumn-module--columnItem--448ba";
export var columnItemDescription = "cateringOptionsColumn-module--columnItemDescription--01aed";
export var columnItemHeading = "cateringOptionsColumn-module--columnItemHeading--0b0ba";
export var columnItems = "cateringOptionsColumn-module--columnItems--2e4f7";
export var eyebrow = "cateringOptionsColumn-module--eyebrow--6efea";
export var headingContainer = "cateringOptionsColumn-module--headingContainer--782f2";
export var openIcon = "cateringOptionsColumn-module--openIcon--3d7ff";
export var primaryCta = "cateringOptionsColumn-module--primaryCta--6975b";
export var primaryDescription = "cateringOptionsColumn-module--primaryDescription--6ed6f";
export var topContainer = "cateringOptionsColumn-module--topContainer--be16f";