import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useRef, useState } from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import EmbeddedVideo from 'src/components/molecules/embeddedVideo'
import VideoPauseIcon from 'src/images/icons/VideoPauseIcon.webp'
import VideoPlayIcon from 'src/images/icons/VideoPlayIcon.webp'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './basicHero.module.scss'

enum BasicHeroCopyLayout {
  centered = 'centered',
  baseline = 'baseline',
}

export type BasicHeroContent = {
  anchor?: string
  copyLayout?: string
  eyebrowText?: string
  headline?: string
  image?: IGatsbyImageData
  imageAlt?: string
  mobileImage?: IGatsbyImageData
  mobileImageAlt?: string
  videoUrl?: string
  showOverlay?: boolean
}

type Props = {
  content: BasicHeroContent
}

const BasicHero = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    copyLayout = BasicHeroCopyLayout.centered,
    eyebrowText = '',
    headline = '',
    image,
    imageAlt = '',
    mobileImage,
    mobileImageAlt = '',
    videoUrl,
    showOverlay,
  } = content

  console.log({ content })

  const copyLayoutFormatted = copyLayout.toLowerCase()
  let sectionClass = Styles.basicHero
  if (copyLayoutFormatted === BasicHeroCopyLayout.baseline)
    sectionClass += ` ${Styles.baseline}`
  const desktopOnlyImage = !mobileImage

  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoPaused, setVideoPaused] = useState(false)
  const toggleVideo = () => {
    if (videoRef.current?.paused) videoRef.current.play()
    else videoRef.current?.pause()

    setVideoPaused(videoRef.current?.paused ?? false)
  }

  const videoPlayOrPauseIcon = videoPaused ? 'play' : 'pause'

  return (
    <section id={anchor} className={sectionClass}>
      <div className={Styles.backgroundMediaWrapper}>
        {!videoUrl && !!image && (
          <GatsbyImage
            className={`${Styles.image} ${
              desktopOnlyImage ? '' : Styles.desktopImage
            }`}
            image={image}
            alt={imageAlt}
          />
        )}
        {!videoUrl && !!mobileImage && (
          <GatsbyImage
            className={`${Styles.image} ${Styles.mobileImage}`}
            image={mobileImage}
            alt={mobileImageAlt}
          />
        )}
        {videoUrl && (
          <>
            <EmbeddedVideo
              url={videoUrl}
              ariaLabel={headline}
              videoRef={videoRef}
            />
            <button
              type="button"
              className={Styles.videoControl}
              onClick={() => {
                toggleVideo()
              }}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  toggleVideo()
                }
              }}
              aria-label={videoPaused ? 'Play Video' : 'Pause Video'}
            >
              <img
                src={videoPaused ? VideoPlayIcon : VideoPauseIcon}
                alt={`${videoPlayOrPauseIcon} video`}
                aria-label={`basic hero ${videoPlayOrPauseIcon} video image`}
              />
            </button>
          </>
        )}
      </div>
      {showOverlay === true || showOverlay === undefined ? (
        <div className={Styles.overlay} />
      ) : (
        ''
      )}
      <div className={Styles.content}>
        <p>{eyebrowText}</p>
        <h1>{BertholdTitle({ text: headline })}</h1>
      </div>
    </section>
  )
}

export default BasicHero
