import * as React from 'react'
import { graphql } from 'gatsby'

import ImageGalleryGrid from 'src/components/organisms/ctas/imageGalleryGrid'
import { PrismicPageDataBodyImageGallery } from 'src/typings/generated/graphql'

interface Props {
  slice: PrismicPageDataBodyImageGallery
}

const ImageGallerySlice = ({ slice }: Props) => {
  const content = {
    id: slice.id,
    items: slice?.items,
    anchor: slice.primary.anchor ?? undefined,
    heading: slice.primary.heading?.text ?? undefined,
  }

  return <ImageGalleryGrid content={content} />
}

export default ImageGallerySlice

export const imageGalleryFragment = graphql`
  fragment PageDataBodyImageGallery on PrismicPageDataBodyImageGallery {
    id
    primary {
      anchor
      heading {
        text
      }
    }
    items {
      image {
        gatsbyImageData
        alt
      }
    }
  }
`
