import React from 'react'
import { Link } from 'gatsby'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as Styles from './cateringOptionsColumn.module.scss'

type Props = {
  content: {
    id: string
    items: any[]
    anchor: string
    description: string
    eyebrowText: string
    headline: string
    primaryCtaLink: {
      url: string
    }
    primaryCtaTarget: string
    primaryCtaText: string
  }
}

const CateringOptionsColumn = (props: Props) => {
  const { content } = props

  const {
    items,
    description,
    eyebrowText,
    headline,
    primaryCtaLink,
    primaryCtaTarget,
    primaryCtaText,
  } = content

  return (
    <section className={Styles.cateringOptionsColumn}>
      <div className={Styles.topContainer}>
        <div className={Styles.headingContainer}>
          <p className={Styles.eyebrow}>{eyebrowText}</p>
          <h2>{headline}</h2>
          <Link
            to={primaryCtaLink.url}
            target={primaryCtaTarget}
            className={`${GlobalStyles.redBasicLink} ${Styles.primaryCta}`}
          >
            {primaryCtaText}
          </Link>
        </div>
        <div className={Styles.primaryDescription}>
          <p>{description}</p>
        </div>
      </div>
      <div className={Styles.columnContainer}>
        <div className={Styles.columnItems}>
          {items.map((item: any, index: number) => {
            const {
              cta_link: ctaLink,
              cta_text: ctaText,
              description: itemDescription,
              cateringHeadline,
              image,
            } = item
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={item.id} className={Styles.columnItem}>
                <div className={Styles.textContainer}>
                  <GatsbyImage
                    alt={item.image?.alt || ''}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    image={image?.gatsbyImageData}
                  />
                  <div className={Styles.columnHeadingContainer}>
                    <h3 className={Styles.columnItemHeading}>
                      {cateringHeadline}
                    </h3>
                  </div>
                  <div className={Styles.columnCtaContainer}>
                    <p className={Styles.columnItemDescription}>
                      {itemDescription.text}
                    </p>
                    <Link
                      to={ctaLink.url}
                      className={`${GlobalStyles.redBasicLink} ${Styles.columnCta}`}
                    >
                      {ctaText}
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CateringOptionsColumn
