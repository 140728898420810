import * as React from 'react'
import { graphql } from 'gatsby'
import MultiMediaGrid from 'src/components/organisms/slidersAndGrids/multiMediaGrid'

interface Props {
  slice: any
}

const MultiMediaGridSlice = ({ slice }: Props) => {
  const content = {
    quoteText: slice.primary.quote_text?.text ?? undefined,
    quoteSource: slice.primary.quote_source ?? undefined,
    image1: slice.primary.image_1?.gatsbyImageData ?? undefined,
    image1Alt: slice.primary.image_1?.alt ?? undefined,
    image2: slice.primary.image_2?.gatsbyImageData ?? undefined,
    image2Alt: slice.primary.image_2?.alt ?? undefined,
    image3: slice.primary.image_3?.gatsbyImageData ?? undefined,
    image3Alt: slice.primary.image_3?.alt ?? undefined,
    textBlock: slice.primary.text_block?.text ?? undefined,
    ctaParagraph: slice.primary.cta_paragraph?.text ?? undefined,
    ctaText: slice.primary.cta_text ?? undefined,
    ctaLink: slice.primary?.cta_link?.url ?? undefined,
    ctaTarget: slice.primary?.cta_link?.target ?? undefined,
  }

  return <MultiMediaGrid content={content} />
}

export default MultiMediaGridSlice

export const multiMediaFragment = graphql`
  fragment PageDataBodyMultiMediaGrid on PrismicPageDataBodyMultiMediaGrid {
    id
    slice_type
    primary {
      text_block {
        text
      }
      quote_text {
        text
      }
      quote_source
      cta_paragraph {
        text
      }
      cta_text
      cta_link {
        url
        target
      }
      image_1 {
        gatsbyImageData
        alt
      }
      image_2 {
        gatsbyImageData
        alt
      }
      image_3 {
        gatsbyImageData
        alt
      }
      cta_link {
        url
      }
      cta_paragraph {
        text
      }
    }
  }
`
