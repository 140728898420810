import * as React from 'react'
import { graphql } from 'gatsby'
import MultiMediaImageGrid from 'src/components/organisms/slidersAndGrids/multiMediaImageGrid'

interface Props {
  slice: any
}

const MultiMediaImageGridSlice = ({ slice }: Props) => {
  const content = {
    anchor: slice.primary.anchor ?? undefined,
    block1Headline: slice.primary.block_1_headline?.text ?? undefined,
    block1Paragraph: slice.primary.block_1_paragraph?.text ?? undefined,
    block2Image: slice.primary.block_2_image?.gatsbyImageData ?? undefined,
    block2ImageAlt: slice.primary.block_2_image?.alt ?? undefined,
    block3Image: slice.primary.block_3_image?.gatsbyImageData ?? undefined,
    block3ImageAlt: slice.primary.block_3_image?.alt ?? undefined,
    block4Headline: slice.primary.block_4_headline?.text ?? undefined,
    block4Paragraph: slice.primary.block_4_paragraph?.text ?? undefined,
  }

  return <MultiMediaImageGrid content={content} />
}

export default MultiMediaImageGridSlice

export const multiMediaFragment = graphql`
  fragment PageDataBodyMultiMediaImageGrid on PrismicPageDataBodyMultiMediaImageGrid {
    id
    slice_type
    primary {
      anchor
      block_1_headline {
        text
      }
      block_1_paragraph {
        text
      }
      block_2_image {
        alt
        gatsbyImageData
      }
      block_3_image {
        alt
        gatsbyImageData
      }
      block_4_headline {
        text
      }
      block_4_paragraph {
        text
      }
    }
  }
`
