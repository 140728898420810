import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as Styles from './multiMediaImageGrid.module.scss'

export type GridContent = {
  anchor: string
  block1Headline: string
  block1Paragraph: string
  block2Image: IGatsbyImageData
  block2ImageAlt: string
  block3Image: IGatsbyImageData
  block3ImageAlt: string
  block4Headline: string
  block4Paragraph: string
}

type Props = {
  content: GridContent
}

const MultiMediaImageGrid = ({ content }: Props) => {
  const {
    anchor,
    block1Headline,
    block1Paragraph,
    block2Image,
    block2ImageAlt,
    block3Image,
    block3ImageAlt,
    block4Headline,
    block4Paragraph,
  } = content

  return (
    <section id={anchor} className={Styles.multiMediaImageGrid}>
      <div className={Styles.gridContainer}>
        <div className={Styles.gridItem}>
          <p className={Styles.textBlockHeadline}>{block1Headline}</p>
          <p className={Styles.textBlockParagraph}>{block1Paragraph}</p>
        </div>
        <div className={Styles.gridItem}>
          <GatsbyImage image={block2Image} alt={block2ImageAlt} />
        </div>
        <div className={Styles.gridItem}>
          <GatsbyImage image={block3Image} alt={block3ImageAlt} />
        </div>
        <div className={Styles.gridItem}>
          <p className={Styles.textBlockHeadline}>{block4Headline}</p>
          <p className={Styles.textBlockParagraph}>{block4Paragraph}</p>
        </div>
      </div>
    </section>
  )
}

export default MultiMediaImageGrid
