import React, { useEffect } from 'react'
import { graphql, HeadProps, PageProps } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
// import { CircleFadeLoader } from 'react-loaders-kit'
import {
  parseSlicesForSchemaType,
  SchemaType,
  buildHasMenuItemsArray,
  buildFaqMainEntityArray,
} from 'src/utils/prismicPageHelper'

import SliceZone from 'src/components/pageLayout/sliceZone'

import { WithContext, WebSite, Menu, FAQPage } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'

import {
  PageQuery,
  PrismicPage,
  PrismicSiteInformation,
  Site,
} from 'src/typings/generated/graphql'

import * as Styles from './{prismicPage.url}.module.scss'

const PageTemplate = ({ data }: PageProps<PageQuery>) => {
  if (!data) return null

  const page = data.prismicPage as PrismicPage

  const redirectUrl = page.data.redirect_url?.url

  useEffect(() => {
    const redirectPage = () => {
      if (typeof window !== 'undefined' && redirectUrl) {
        window.location.href = redirectUrl
      }
    }
    setTimeout(() => {
      redirectPage()
    }, 500)
  }, [])

  return (
    <div data-testid="page-template">
      {redirectUrl && (
        <div className={Styles.loaderContainer}>
          <div className={Styles.spinner} />
          <p>You are being redirected...</p>
          <div />
        </div>
      )}
      {!redirectUrl && <SliceZone sliceZone={page.data.body} />}
    </div>
  )
}

export default withPrismicPreview(PageTemplate)

export const Head = ({ data }: HeadProps<PageQuery>) => {
  const { prismicPage, prismicSiteInformation, site } = data

  const { data: pageData, url } = prismicPage as PrismicPage
  const { siteMetadata } = site as Site
  const siteInformation = prismicSiteInformation as PrismicSiteInformation

  const title =
    pageData.meta_title ?? pageData.document_display_name?.text ?? 'Blog'
  const description =
    pageData.meta_description ??
    siteInformation.data.site_description ??
    siteMetadata?.description ??
    ''
  const imageUrl =
    pageData.meta_image?.url ??
    prismicSiteInformation?.data.site_icon?.url ??
    siteMetadata?.logoPath ??
    ''
  const noIndex = pageData.meta_no_index ?? undefined
  const schemaType = parseSlicesForSchemaType(pageData.body)
  let schema: WithContext<Menu | WebSite | FAQPage>

  if (schemaType === SchemaType.menu) {
    schema = {
      '@context': 'https://schema.org',
      '@type': 'Menu',
      url: prismicSiteInformation?.data.menu_page?.url ?? '',
      name: 'Raising Canes Chicken Fingers Menu',
      image: prismicSiteInformation?.data.site_icon?.url ?? '',
      description,
      hasMenuItem: buildHasMenuItemsArray(
        pageData.body,
        prismicPage?.url ?? ''
      ),
    }
  } else if (schemaType === SchemaType.faq) {
    schema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: buildFaqMainEntityArray(),
    }
  } else {
    schema = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: url ?? '',
      name: title,
      description,
      image: {
        '@type': 'ImageObject',
        url: imageUrl,
      },
    }
  }

  return DocumentHead({
    title,
    url: url ?? '',
    description,
    imageUrl,
    pageSchema: schema,
    noIndex,
  })
}

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      _previewable
      data {
        document_display_name {
          text
        }
        redirect_url {
          url
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyArtOfAPerfectMeal
          ...PageDataBodyArticlesHero
          ...PageDataBodyArticlesLibrary
          ...PageDataBodyBannerCta
          ...PageDataBodyBasicHero
          ...PageDataBodyBasicSlider
          ...PageDataBodyCallToAction
          ...PageDataBodyCardsCta
          ...PageDataBodyCateringOptions
          ...PageDataBodyCommunityInvolvement
          ...PageDataBodyCrewCallout
          ...PageDataBodyCrewRising
          ...PageDataBodyDescriptiveSlider
          ...PageDataBodyDoubleCta
          ...PageDataBodyDynamicCta
          ...PageDataBodyECardWidget
          ...PageDataBodyEmbeddedContent
          ...PageDataBodyFaq
          ...PageDataBodyFaqCategory
          ...PageDataBodyFaqCta
          ...PageDataBodyHeroSlider
          ...PageDataBodyHighlights
          ...PageDataBodyHostAFundraiser
          ...PageDataBodyHowItWorks
          ...PageDataBodyImageGrid
          ...PageDataBodyImageGallery
          ...PageDataBodyImageReel
          ...PageDataBodyImageStrip
          ...PageDataBodyLinkGrid
          ...PageDataBodyLocator
          ...PageDataBodyLogoCta
          ...PageDataBodyMenuItemGroup
          ...PageDataBodyMinimalHero
          ...PageDataBodyMultiMediaGrid
          ...PageDataBodyMultiMediaImageGrid
          ...PageDataBodyNewsroomArticlesLibrary
          ...PageDataBodyProfileHighlight
          ...PageDataBodyQuote
          ...PageDataBodyRecentArticles
          ...PageDataBodyRichTextCta
          ...PageDataBodySaucyCarousel
          ...PageDataBodySecondaryNavigation
          ...PageDataBodyStatGrid
          ...PageDataBodyTimeline
          ...PageDataBodyTitleAndDescription
          ...PageDataBodyUpcomingRestaurants
          ...PageDataBodyTable
        }
        meta_title
        meta_description
        meta_image {
          url
        }
        meta_no_index
      }
      url
    }
    prismicSiteInformation {
      data {
        site_icon {
          url
        }
        site_description
        menu_page {
          url
        }
      }
    }
    site {
      siteMetadata {
        description
        logoPath
      }
    }
  }
`
