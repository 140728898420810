import React, { useRef, useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import * as GlobalStyles from 'src/styles/global.module.scss'
import EmbeddedVideo from 'src/components/molecules/embeddedVideo'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import VideoPauseIcon from 'src/images/icons/VideoPauseIcon.webp'
import VideoPlayIcon from 'src/images/icons/VideoPlayIcon.webp'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './heroSlider.module.scss'

export type HeroSliderItem = {
  headline?: string
  eyebrowText?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  linkText?: string
  mobileImage?: IGatsbyImageData
  mobileImageAlt?: string
  videoUrl?: string
  mobileVideoUrl?: string
}

export type HeroSliderContent = {
  anchor?: string
  items?: HeroSliderItem[]
}

type Props = {
  content: HeroSliderContent
}

const HeroSlider = ({ content }: Props) => {
  const { anchor = generateRandomId(), items = [] } = content

  let slideCount: number
  let slideClass: string
  if (items.length === 1) {
    slideCount = 1
    slideClass = `${Styles.slider} ${Styles.trackSingular}`
  } else {
    slideCount = 1.5
    slideClass = `${Styles.slider}`
  }

  const generateSlide = (item: HeroSliderItem) => {
    const {
      headline = '',
      eyebrowText = '',
      image,
      imageAlt = '',
      mobileImage,
      mobileImageAlt = '',
      link,
      linkText = '',
      videoUrl,
      mobileVideoUrl,
    } = item
    const desktopOnlyImage = !mobileImage

    const videoRefMobile = useRef<HTMLVideoElement>(null)
    const videoRefDesktop = useRef<HTMLVideoElement>(null)
    const [videoPausedDesktop, setVideoPausedDesktop] = useState(false)
    const [videoPausedMobile, setVideoPausedMobile] = useState(false)
    const toggleVideo = () => {
      if (videoRefDesktop.current?.paused) videoRefDesktop.current.play()
      else videoRefDesktop.current?.pause()

      if (videoRefMobile.current?.paused) videoRefMobile.current.play()
      else videoRefMobile.current?.pause()

      setVideoPausedDesktop(videoRefDesktop.current?.paused ?? false)
      setVideoPausedMobile(videoRefMobile.current?.paused ?? false)
    }

    return (
      <div className={Styles.slideContainer}>
        <div className={Styles.slide}>
          <div className={Styles.mediaWrapper}>
            {!videoUrl && !!image && (
              <GatsbyImage
                image={image}
                alt={imageAlt}
                className={`${Styles.media} ${
                  desktopOnlyImage ? '' : Styles.desktopImage
                }`}
              />
            )}
            {!videoUrl && !!mobileImage && (
              <GatsbyImage
                image={mobileImage}
                alt={mobileImageAlt}
                className={`${Styles.media} ${Styles.mobileImage}`}
              />
            )}
            {videoUrl && (
              <>
                <div className={`${Styles.media} ${Styles.desktopMedia}`}>
                  <EmbeddedVideo
                    url={videoUrl}
                    ariaLabel={headline}
                    videoRef={videoRefDesktop}
                  />
                  <button
                    type="button"
                    className={Styles.videoControl}
                    onClick={() => {
                      toggleVideo()
                    }}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        toggleVideo()
                      }
                    }}
                    aria-label={
                      videoPausedDesktop ? 'Play Video' : 'Pause Video'
                    }
                  >
                    <img
                      src={videoPausedDesktop ? VideoPlayIcon : VideoPauseIcon}
                      alt=""
                    />
                  </button>
                </div>
                <div className={`${Styles.media} ${Styles.mobileMedia}`}>
                  <EmbeddedVideo
                    url={mobileVideoUrl || videoUrl}
                    ariaLabel={headline}
                    videoRef={videoRefMobile}
                  />
                  <button
                    type="button"
                    className={Styles.videoControl}
                    onClick={() => {
                      toggleVideo()
                    }}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        toggleVideo()
                      }
                    }}
                    aria-label={
                      videoPausedMobile ? 'Play Video' : 'Pause Video'
                    }
                  >
                    <img
                      src={videoPausedMobile ? VideoPlayIcon : VideoPauseIcon}
                      alt=""
                    />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={Styles.content}>
            <div className={Styles.contentWrapper}>
              <p className={Styles.preHeadline}>{eyebrowText}</p>
              <h1 className={Styles.headline}>
                {BertholdTitle({ text: headline })}
              </h1>
              {link?.url && (
                <CustomLink link={link} className={GlobalStyles.redButton}>
                  {linkText}
                </CustomLink>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section id={anchor} className={Styles.heroSliderSlice}>
      <div className={`${Styles.hero} ${Styles.mobile}`}>
        {generateSlide(items[0])}
      </div>
      <div className={`${Styles.hero} ${Styles.web}`}>
        <div className={Styles.sliderContainer}>
          <Slider
            slidesToShow={slideCount}
            infinite={false}
            accessibility
            arrows={false}
            className={slideClass}
            vertical={false}
          >
            {items.map((item, i: number) => {
              const key = `hero-slider-${i}`
              return <div key={key}>{generateSlide(item)}</div>
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default HeroSlider
