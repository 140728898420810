import { graphql } from 'gatsby'
import React from 'react'
import BasicHero, {
  BasicHeroContent,
} from 'src/components/organisms/heroes/basicHero'
import { PrismicPageDataBodyBasicHero } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyBasicHero
}

const BasicHeroSlice = ({ slice }: Props) => {
  const content: BasicHeroContent = {
    anchor: slice.primary.anchor ?? undefined,
    copyLayout: slice.primary.copy_layout ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    image: slice.primary.image?.gatsbyImageData ?? undefined,
    imageAlt: slice.primary.image?.alt ?? undefined,
    mobileImage:
      slice.primary.image?.thumbnails?.mobile?.gatsbyImageData ?? undefined,
    mobileImageAlt: slice.primary.image?.thumbnails?.mobile?.alt ?? undefined,
    videoUrl: slice.primary.video?.url ?? undefined,
    showOverlay: slice.primary.show_overlay ?? undefined,
  }

  return <BasicHero content={content} />
}

export default BasicHeroSlice

export const BasicHeroFragment = graphql`
  fragment PageDataBodyBasicHero on PrismicPageDataBodyBasicHero {
    primary {
      anchor
      eyebrow_text
      headline
      copy_layout
      show_overlay
      image {
        gatsbyImageData
        alt
        thumbnails {
          mobile {
            gatsbyImageData
            alt
          }
        }
      }
      video {
        url
      }
    }
  }
`
