// extracted by mini-css-extract-plugin
export var accordingHeadingContainer = "cateringOptionsAccordion-module--accordingHeadingContainer--7db56";
export var accordionContainer = "cateringOptionsAccordion-module--accordionContainer--d76ca";
export var accordionCta = "cateringOptionsAccordion-module--accordionCta--fcd48";
export var accordionCtaContainer = "cateringOptionsAccordion-module--accordionCtaContainer--0fa26";
export var accordionImage = "cateringOptionsAccordion-module--accordionImage--9d4e9";
export var accordionImageActive = "cateringOptionsAccordion-module--accordionImageActive--72b8b";
export var accordionImageContainer = "cateringOptionsAccordion-module--accordionImageContainer--bfa6b";
export var accordionItem = "cateringOptionsAccordion-module--accordionItem--9aab0";
export var accordionItemDescription = "cateringOptionsAccordion-module--accordionItemDescription--e8b50";
export var accordionItemHeading = "cateringOptionsAccordion-module--accordionItemHeading--0f915";
export var accordionItems = "cateringOptionsAccordion-module--accordionItems--6ca7e";
export var cateringOptionsAccordion = "cateringOptionsAccordion-module--cateringOptionsAccordion--3a9b5";
export var eyebrow = "cateringOptionsAccordion-module--eyebrow--4a27a";
export var headingContainer = "cateringOptionsAccordion-module--headingContainer--fff2b";
export var open = "cateringOptionsAccordion-module--open--35dce";
export var openIcon = "cateringOptionsAccordion-module--openIcon--af563";
export var primaryCta = "cateringOptionsAccordion-module--primaryCta--7fafa";
export var primaryDescription = "cateringOptionsAccordion-module--primaryDescription--1687a";
export var topContainer = "cateringOptionsAccordion-module--topContainer--a3987";